import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import * as _ from 'lodash';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  songContent: {
    lineHeight: 1,
  },
  paper: {
    display: 'flex',
    textAlign: 'center',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  link: {
    boxShadow: `none`,
  },
}));

export default function Song(props) {
  const post = props.data.markdownRemark;

  const siteTitle = props.data.site.siteMetadata.title;
  // const { previous, next } = props.pageContext

  const classes = useStyles();

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title={`${post.frontmatter.title} by ${post.frontmatter.artist} lyrics`}
        description={`Sinhala Lyrics for ${post.frontmatter.title} by ${post.frontmatter.artist} in ChordSL.com`}
      />
      <React.Fragment>
        <Container maxWidth="md">
          <Box my={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h4"
                  component="h1"
                  className={classes.title}
                >
                  {post.frontmatter.title}
                </Typography>
                <Box mt={1}>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h6" component="h2">
                        {post.frontmatter.artist}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box ml={3}>
                        <Button
                          to={`/lyrics-by-artist/${_.kebabCase(
                            post.frontmatter.artist
                          )}`}
                          component={Link}
                          className={classes.link}
                          variant="outlined"
                          size="small"
                        >
                          More
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Container maxWidth="md">
          <Card>
            <CardContent>
              <pre>
                <Typography className={classes.songContent} variant="body2">
                  <section
                    dangerouslySetInnerHTML={{
                      __html: post.rawMarkdownBody,
                    }}
                  />
                </Typography>
              </pre>
            </CardContent>
          </Card>
        </Container>
      </React.Fragment>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      rawMarkdownBody
      frontmatter {
        title
        artist
      }
    }
  }
`;
